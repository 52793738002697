import React, { useEffect, useState,useMemo, useRef } from 'react'
import './CoursesList.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as utilityActions from '../../redux/actions/utilityActions'
import CourseEdit from './CourseEdit';
import PrivilegeHelper from '../../utility/PrivilegeHelper';
import * as userActions from '../../redux/actions/userActions'
import { useToasts } from 'react-toast-notifications';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GTranslateIcon from '@mui/icons-material/GTranslate';
// import TextRotateVerticalIcon from '@mui/icons-material/TextRotateVertical';
// import DescriptionIcon from '@mui/icons-material/Description';
import SimCardDownloadSharpIcon from '@mui/icons-material/SimCardDownloadSharp';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import APIConstant from '../../utility/APIConstant';
import CopyIcon from '../../assets/img/ic_content-copy.svg';
import ModalForm from './Modal/ModalForm';
import { Icon } from '@material-ui/core';



const CoursesList = ({ isClient, configs, courses, user,   isManager=false }) => {


    // let cateList = [
    //     { "id": 1, "category": "Import", "courses": [] },
    //     { "id": 2, "category": "Export", "courses": [] },
    //     { "id": 3, "category": "Sanctions", "courses": [] },
    //     { "id": 4, "category": "Miscellaneous", "courses": [] },
    //     { "id": 5, "category": "Security & Trade", "courses": [] },
    //     { "id": 6, "category": "Corruption & Antiboycott", "courses": [] }]

        
        
    const dispatch = useDispatch()    
    const [opened, setMenuOpen] = useState(-1);
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const utilityInfo = useSelector(state => state.utility);
    const userInfo = useSelector(state => state.user);
    const [isIconShown, setIsIconShown] = useState(false);
    const [courseLoader, setCourseLoader] = useState(true)
    const cateList =useSelector((state)=>state.user.courseCategory).map(item=>{return{...item,category:item.coursecategory,courses:[]}})
    const [categoryList, setCategoryList] = useState([...cateList]);
    const { addToast } = useToasts();
    const [expanded, setExpanded] = useState(false);
    const [isScormGenerating, setIsScormGenerating] = useState(false);
    const [scormConfig, setScormConfig] = useState('');
    const scromLoader = useSelector(state=>state.user.isLoading)
    const courseApproval = useSelector(state=>state.user.courseApproval)
    const languageConfig=useSelector(state=>state.user.languageConfig)
    const languagesList=useSelector(state=>state.user.languagesList)
    const textDownload =useSelector(state=>state.user.textDownload)
    const userDetails= useSelector(state=>state.user.loggedInUser)
    const s3Config= useSelector(state=>state.user.s3Config)
    const quizDownload = useSelector(state=>state.user.quizDownload)
    const [modelOpen, setModelOpen] = useState(false);
    const clientsList = useSelector((state) => state.client)
    const [modelId, setModelId] = useState(false);
    const [form,setForm]=useState([])
    const [language,setLanguage]=useState({})
    const [modelName,setModelName]=useState("")
    const [selectedChips, setSelectedChips] = useState("en_US");
    const [slectedCheckBox,setSelectedCheckbox]=useState({})
    const [multiConfig,setMultiConfig]=useState({})
    const multilingualConfig = useSelector(state=>state.user.multilingualConfig)
    const [selectedConfigId, setSelectedConfigId] = useState(null);
    const languageAprrove=useRef({})
 

    const directLogin=useSelector((state)=>state.user.directLogin)
    const handleModalOpen = (configId) => {
        setSelectedConfigId(configId);
      };
    
      const handleModalClose = () => {
        setSelectedConfigId(null);
      };
    
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    // we only need to show package course for allowedUser.
    const showPackageIcon=()=>{
        const allowedUsers=s3Config?.clientAdmin;
        const currentUser=userDetails.email;
        return allowedUsers?.filter(item=>item===currentUser).length>0
    }

    useEffect(()=>{
        if(languageConfig=="success"){
            handleClose()
            addToast( "Courses Packaged successfully", {
                appearance: "success",
                autoDismiss: true,
                placement: "top-right",
            } );
        }
        dispatch(userActions.languageConfigResponse(""))
    },[languageConfig])

    useEffect(()=>{
        let temp={}
        for(let i=0;i<configs.length;i++){    
            let course=multilingualConfig.filter(item=>{
               return JSON.parse(item?.course_data).id===configs[i]?._id
            })
                if( configs[i]?.info?.config?.lan!=="en_US" && configs[i]?.info && configs[i]?.info?.config?.lan && (course[0]?.status===1 || courseApproval.includes(configs[i]?._id)))      {
                    if( temp[configs[i]?.info?.config?.lan]){
                        temp[configs[i]?.info?.config?.lan]=[...temp[configs[i]?.info?.config?.lan],[configs[i]?._id,configs[i]?.configname]]
                    }
                    else{
                        temp[configs[i]?.info?.config?.lan]=[[configs[i]?._id,configs[i]?.configname]]
                    }         
                }    
        }
     setLanguage({...temp})
    },[configs,courseApproval,multilingualConfig])

    useEffect(() => { 
        if (JSON.stringify(cateList) !== JSON.stringify(categoryList) && categoryList.length===0) {
            setCategoryList(cateList);
        }
    }, [cateList, categoryList]);
    useEffect(() => {
        if (!expanded) setMenuOpen(-1);
    }, [expanded])
    useEffect(() => {
        let sco = JSON.parse(window.sessionStorage.getItem('sco'));
        if(sco){
            setIsScormGenerating(sco?.isScormGenerating);
            setScormConfig(sco?.configId)
        }
            // if (!sco?.isScormGenerating && sco?.configId !=='') alert((APIConstant.DEPLOY_ENV==='prod'? 'https://tradecompliancecourses.com/scormpacks/' : 'https://tradecompliance.training/scormpacks/') + scormConfig + '.zip');
    }, [isScormGenerating])

    useEffect(() => {
        setCourseLoader(true);
        if (Array.isArray(courses)) {
            courses.forEach((el, cIndex) => {
                categoryList.forEach((category, categoryIndex) => {
                    const dublicate = categoryList[categoryIndex]?.courses?.findIndex(obj => {
                        return obj.id === categoryIndex + '_' + cIndex;
                    });
                    if (Number(el?.coursecategory) === Number(category.id) && dublicate === -1) {
                        categoryList[categoryIndex].courses.push({ "id": categoryIndex + '_' + cIndex, "courseList": el })
                    }
                })
            })
            categoryList.forEach(el => {
                el.courses.sort((a,b) => (a.courseList.coursename > b.courseList.coursename) ? 1 : ((b.courseList.coursename > a.courseList.coursename) ? -1 : 0))
            })
            setCourseLoader(false);
            setCategoryList(categoryList);
        }
    }, [courses])
    const closeMenuItem = (item, title, type) => {
        if (type === 4) {
            dispatch(utilityActions.setSelectedSupplimentaryReq(item, 4))
        }else if(type === 5){
           let allCourseStructure=userInfo?.allCourseStructure;
           dispatch(utilityActions.setSelectedHistoryReq(item,5,allCourseStructure)); 
        }else{
            dispatch(utilityActions.toggleSideSheet({
                width: 800,
                direction: 'right',
                title: title,
                open: true,
                component: <CourseEdit data={item} />
            }))
        }
        setMenuOpen(-1);
    }

    const handleMenuOpen = (id) => {
        (opened === id) ? setMenuOpen(-1) : setMenuOpen(id);
    }

    /** Role Id hard coded */
    const openAuthor = (cr, type) => {
        let mode = (type === 0 ? 'course' : 'config');
        //getSessionData()
        if(user.role!==4){
            dispatch(userActions.courseConfigRequest({courseId:cr?._id,user:user,mode:mode,cr:cr,activeClient:clientsList.activeClient}))
        }
        else{
            var json = window.btoa(JSON.stringify({
                "platform_scorm": { 'userId': user._id, 'cid': cr?._id },
                "ceuser": {
                    "uid": user._id, "firstname": user.firstname, "lastname": user.lastname, 'email': user.email, "pvtid": user?.clients[0]?.clientid || 139,
                    "role_id": user.role, "type": mode
                }, "cemodule": { "course_Id": cr?._id, "coursename": cr?.coursename }
            }));
            window.open(`${APIConstant.getUrl(Number(cr?.uisettings?.version) === 30?'ceauthor_v30':'ceauthor')}?data=${json}`);
        }
    }

    // type 0 is course 
    // type 1 is config 
    // identify the difference 
    const openPlayer = (cr, type,checkObj) => {

        fetch(`${APIConstant.getUrl('capi')}courses/getCelmsCourseid/id/${cr._id}~${userInfo?.authInfo?.user_Id || 42075}`).then(response => response.json()).then(data => {
            if (data?.status === 'success' ) {
                const courseId = data?.data?.celms_course_id;
                const authToken = userInfo?.authInfo?.learner_token || APIConstant.getUrl("fixedauth")
                window.open(`${APIConstant.getUrl('player')}?c=${courseId}&t=${authToken}&p=v_${Number(cr?.uisettings?.version) === 30? 30 : 20}&m=2&i=${cr?._id}&f=${type}&activeClient=${getActiveClient()}`)
                // setCourseLoader(false)
            } else {
                addToast( "Course configuration is not locked. Please lock the configuration and check again", {
                    appearance: "success",
                    autoDismiss: true,
                    placement: "top-right",
                } );
            }
        })

        // window.open(`${APIConstant.getUrl('player')}?c=${data?.data?.celms_course_id}&t=${authtoken}&p=v_20&m=2&i=${cr?._id}&f=${type}`)


        // setCourseLoader(true)
        // fetch(`${APIConstant.getUrl('capi')}courses/lmsrlm_autologin`).then(response => response.json()).then(data => {
        //     if (data?.data?.status === 'success') {
        //         console.log(data?.data?.data?.learner_token);
        //         let authtoken = data?.data?.data?.learner_token
        //         fetch(`${APIConstant.getUrl('capi')}courses/getCelmsCourseid/id/${cr._id}`).then(response => response.json()).then(data => {
        //             if (data?.status === 'success') {
        //                 console.debug("course data========", data?.data?.celms_course_id)
        //                 window.open(`${APIConstant.getUrl('player')}?c=${data?.data?.celms_course_id}&t=${authtoken}&p=v_20&m=2&i=${cr?._id}&f=${type}`)
        //                 setCourseLoader(false)
        //             } else {
        //                 console.debug("celmsid not found")
        //             }
        //         })

        //     }
        // }
       // );

    }

    const getActiveClient = () => {
        try {
           let activePvtId = clientsList?.allClientList.filter(el=>{
                return el?._id === clientsList?.activeClient
            })
            return  activePvtId?.[0]?.clientid || '139'
        } catch (e) {
            console.log(e);
        }
    }

    const getSessionData = () => {
        try {

            let u = window.sessionStorage.getItem('user');
            let p = window.sessionStorage.getItem('p')
            if (p && u) {
                let user = JSON.parse(u);
                let pass = window.atob(p);
                return { email: user?.email, password: pass }
            }
        } catch (e) {
            console.log(e);
        }
    }

    // const createScormPackage = (configId) => {
    //     setIsScormGenerating(true)
    //     setScormConfig(configId)
    //     window.sessionStorage.setItem('sco', JSON.stringify({'isScormGenerating':true, 'configId':configId}))
    //     // window.open(`https://tradecompliance.training/xapice/api/courseconfig/getscormpackage/${configId}/4`);
    //     dispatch(userActions.createScormPackage(configId));
    //         addToast("Generating Scorm Package. ", {
    //             appearance: "success",
    //             autoDismiss: true,
    //             placement: "bottom-center",
    //         });
       
    // }
    const handleIcon = () =>{
        setIsIconShown(true);
    }

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
        addToast( "ID Copied Successfully", {
            appearance: "success",
            autoDismiss: true,
            placement: "top-right",
        } );
    };
    
    useEffect(()=>{
        if(!modelOpen){
            setForm([])
        }
        if(modelOpen){
            setForm([...getForm(selectedChips)])
        }
    },[modelOpen])

    // Modal Handlers
    const handleOpen = async (conf) => {
        setModelId(conf?._id)
        setModelName(conf?.configname)
        setModelOpen(true)
      
    };

    const handleClose = () => {
        setModelOpen(false)
        setSelectedChips("en_US")
    };

    const handleConfig=(item,lancode,e)=>{    
        if(lancode==="en_US"){
            return
        }    
        // let value=configforLanguage
        let temp=slectedCheckBox;
        if(e.target.checked){
            // value[lancode]=item[0]+"-"+item[1]
            let found=[]
                    if(temp&&temp?.[modelId]&&Object.keys(temp?.[modelId]).length>0){
                found =temp?.[modelId]?.filter(key=>{
                return key.lan==lancode
                })
            }
            else{
                let temp=slectedCheckBox; 
                if (temp) {
                    temp[modelId] = [];
                  }   
            }
        if(found.length){
            let selection=temp?.[modelId]?.map(key=>{

                if(key.lan===lancode){
                    key.id=item[0]
                    key.name=item[1]
                    key.lan=lancode
                }
                return key
            }
        )
        if (temp && temp[modelId]) {
            temp[modelId] = selection;
        }
        }
        else{
            let temp=slectedCheckBox;    
          temp?.[modelId]?.push({
                id:item[0],
                name:item[1],
                lan:lancode
            })
        }

        }
        else{
            let temp=slectedCheckBox;
                let selection=temp?.[modelId]?.filter(key=>{
    
                    if(key.lan!==lancode){      
                        return key
                    }
                }
            )
    
            if (temp && temp[modelId]) {
                temp[modelId] = selection;
            }
            // delete value[lancode]
        }
         setSelectedCheckbox({...temp})
    }
    // slecting chip
    const handelLanguaeArray =(lancode)=>{
       setSelectedChips(lancode);    
    }

    const getForm=(lancode)=>{
        let value=[]
    
        let temp=slectedCheckBox
             //   checking for previously selected options
        let selection=false

        if(modelId.length ){
            if(temp && temp?.[modelId] && Object.keys(temp?.[modelId]).length>0){
                let selected=temp?.[modelId]?.filter(item=>item.lan===lancode) 
                if (selected && selected.length > 0) {
                    selection = `${selected[0].id}_-${selected[0].name}`;
                }
            }
        }
       
 
    
        if(lancode==="en_US"){
            language["en_US"]=[[modelId,modelName]]
        }
            value =language?.[lancode].map((item,idx)=>{
                return (<FormControlLabel  control={<Checkbox  disabled={lancode==="en_US"?true:false} checked={lancode!=="en_US"?item[0]+"_-"+item[1]==selection||false:true} />} label={item[1]} onClick={(e)=>handleConfig(item,lancode,e)}/>)
            })
            delete language["en_US"]
            return value
       
    }
    
    useEffect(()=>{
        setForm([...getForm(selectedChips)])
    },[selectedChips,slectedCheckBox])
// data for Localization Modal
const getDiscription=(language)=>{
    let value=[]
    value.push(<Chip className="chip" label="English" color={selectedChips==="en_US"?"success":"primary"} variant={selectedChips==="en_US"?"":"outlined"} onClick={()=>handelLanguaeArray("en_US")} />)

    if(Object.keys(language).length>1 || (Object.keys(language).length=1 && !language["en_US"]))  
        {Object.keys(language).forEach((lancode)=>{        
                const lan = languagesList[0].filter(item=>item.language_code===lancode)         
                value.push(<Chip className="chip" label={lan?.[0]?.language_name} id={language?.[lancode]?.[0]?.[0]}  color={selectedChips===lancode?"success":"primary"} variant={selectedChips==lancode?"":"outlined"} onClick={()=>handelLanguaeArray(lancode)} />)
     })}
    return value
}



useEffect(()=>{
    if(!textDownload){
        addToast("Course has no Text to Downlaod ", {
            appearance: "warning",
            autoDismiss: true,
            placement: "bottom-center",
        });
    }
    dispatch(userActions.textDownloadRes(true))
},[textDownload])

useEffect(()=>{
    if(!quizDownload){
        addToast("Course has no Quiz to Downlaod ", {
            appearance: "warning",
            autoDismiss: true,
            placement: "bottom-center",
        });
    }
    dispatch(userActions.quizDownloadRes(true))
},[quizDownload])


const chips= useMemo(()=>getDiscription(language),[language,selectedChips])
// Modal for Localization
    const LocalizationModal=(config)=>{
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: '#fff',
            border: '1px solid #757575',
            boxShadow: 2,
            borderRadius: 5, 
            p: 4,
          };     
        return (
            <Modal
            open={modelOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
                <i class="fa fa-times fs-5 ms-2 position-absolute modal-close-icon" style={{color:"#ff0a3c",cursor:"pointer"}} onClick={handleClose} aria-hidden="true"></i>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Localization Language
                 </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Stack direction="row" className='stack' useFlexGap flexWrap="wrap" >  
               { chips }      
                {/* {getDiscription(language)} */}
                </Stack>
                <FormGroup sx={{height:"10vh",maxHeight:"60vh",overflowY:"scroll",flexWrap:"nowrap"}}> 
                {modelOpen?form:<></>}
                 </FormGroup>
                <div className='d-flex justify-content-end'>
                <button className='btn btn-localization submit-btn' onClick={(()=>{ 

                    // formation of data for course language configouration based on checkbox selection
                    let configx={}
                    if(Object.keys(slectedCheckBox?.[modelId]).length>0){
                        slectedCheckBox?.[modelId]?.forEach(item=>{
                            configx[item.lan]=`${item.id}_-${item.name}`
                        })
                    }
                    // adding default config on which we are opening model
                    configx={
                        "en_US":`${modelId}_-${modelName}`,...configx
                    }
                    if(Object.keys(configx).length>1){
                        dispatch(userActions.languageConfigRequest({config:config?._id,configx}))
                        multiConfig[modelId]=[]
                        Object.keys(configx).forEach(item=>{
                            let value=configx[item]
                            let arr = value.split("_-")
                            multiConfig[modelId].push({
                                lan:item,
                                name:arr[1],
                                id:arr[0]
                            })
                        })
                    }
                    else{
                        addToast( "Kindly select Multiple Languges for making configouration", {
                            appearance: "warning",
                            autoDismiss: true,
                            placement: "top-right",
                        } );
                    }

                })}>Submit</button>
                <button className='btn btn-localization' onClick={()=>{
                    setSelectedChips("en_US");
                    setSelectedCheckbox({...slectedCheckBox,[modelId]:multiConfig[modelId]})
                    // setConfig({"en_US":config._id+"-"+config?.configname})
                    }}>Reset</button>
                </div>
              </Typography>
            </Box>
          </Modal>
        )
    }
    const createScormPackage=(configId)=>{
        setScormConfig(configId)
    dispatch(userActions.scromPackageRequest({configId:configId}))
            addToast("Downloading Scorm Package. ", {
                appearance: "success",
                autoDismiss: true,
                placement: "bottom-center",
            });
    }
    const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }}  PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [0, -15    ] } }] }}/>
      ))({
        [`& .MuiTooltip-tooltip`]: {
            backgroundColor:" #336fe3",
            color: 'white',
            fontSize: '1rem',
            // padding: '10px',
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              width: 0,
              height: 0,
              borderTop: '8px solid #336fe3',
              borderRight: '8px solid transparent',
              borderLeft: '8px solid transparent',
              top: '30px',
              left: '50%',
              transform: 'translateX(-50%)',
            }   
        },
      });
    //   we are cheking previously set config to update selection and final data
      useEffect(()=>{
         let temp={}
         if( multilingualConfig && multilingualConfig.length>0){
             multilingualConfig?.forEach(item=>{
                 if(JSON.parse(item.player_config_json)!=null){
                     temp[JSON.parse(item?.course_data).id]=JSON.parse(item.player_config_json)?.i18n?.languages || []
                 }
                 else{
                     temp[JSON.parse(item?.course_data).id]=[]
                 }
             })
         }
         setMultiConfig({...temp})
         setSelectedCheckbox({...temp})
         

      },[multilingualConfig])

      useEffect(()=>{
        if(directLogin){
            setExpanded(configs.id)
        }
      },[directLogin])

      const checkAprroval =(checkObj,id)=>{
        if(checkObj?.status!==1 && !courseApproval.includes(id)){
            return true
        }
        else{
         
            return false
        }
      }

    const renderConfigItems = (configs,role) => {  
        // for andover user we added new accordion (translator)
       if(role){            
        return <>
         <Accordion key={configs.id} expanded={expanded === configs.id} onChange={handleChange(configs.id)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className='color-ce-green' />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>Configured Courses</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <ul className='course-courseList-continer'>
              {configs.map((config, index) => {        
                  let data=[]
                  if( Object.keys(multiConfig).length>0){
                    data=multilingualConfig.filter(item=>{
                      let id =JSON.parse(item.course_data)
                      return id.id===config._id
                   })
                  }
                  //  making a obj if there is multilingual config of particular course to keep a track in UI
                   let checkObj={}
                   if(data.length>0){
                      Object.keys(data[0]).forEach((key)=>{
                          let item=key
                         let ans= data[0]?.[item]
                        checkObj[item]=ans
                      })
                   } 
                 const labelid = `course-courseList-coursename-${index}`;              
                return (<li key={labelid} className="courseList">
                    
                    <div id={labelid} className='course-courseList-coursename'><span>
                         
                        </span>{config?.configname}</div>
                    <div className='course-courseList-btn'>
                        <button className='btn btn-author' onClick={() => openPlayer(config,1,checkObj)}>Preview</button>
                         {(checkObj?.status!==1 && !courseApproval.includes(config._id)) ?<button className='btn btn-preview' onClick={() => openAuthor(config, 1)}>Ce Author </button>:<div style={{width:"116px"}}></div>} 
                    </div>
                </li>
              )})
              }
            </ul>
            </AccordionDetails>
        </Accordion>
        </>
       }
        return  <Accordion key={configs.id} expanded={expanded === configs.id} onChange={handleChange(configs.id)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className='color-ce-green' />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{padding:"0px 16px !important",display:"flex !important"}}
            >
                <Typography sx={{ width: '33%', flexShrink: 0}}>Configured Courses</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <ul className='course-courseList-continer'>
              {configs.map((config, index) => {

                // filtering multi config based on current course id
                // multilingiual config contains info of status of course, config of 
                let data=[]
                if( Object.keys(multiConfig).length>0){
                  data=multilingualConfig.filter(item=>{
                    let id =JSON.parse(item.course_data)
                    return id.id==config._id
                 })
                }
                //  making a obj if there is multilingual config of particular course to keep a track in UI
                 let checkObj={}
                 if(data.length>0){
                    Object.keys(data[0]).forEach((key)=>{
                        let item=key
                       let ans= data[0]?.[item]
                      checkObj[item]=ans
                    })
                 }
                 let id=config._id
                //  number for api endpoint
                 let number=1
                 const labelid = `course-courseList-coursename-${index}`;           
                const current_config=[]
                //  making of tooltip text if course is configured
                let clanguage="configs: English "
                if(multiConfig && Object.keys(multiConfig).length>0 && multiConfig?.[config._id]?.length>0){
                    multiConfig?.[config._id]?.forEach(item=>current_config.push(item.lan))
                    
                    multiConfig?.[config._id].forEach(item=>{
                    const lan = languagesList[0].filter(key=>{
                    if(item?.lan!=="en_US")
                        return key.language_code===item.lan
                    return false
                    }
                    )   
                    clanguage+=lan.length>0?lan?.[0]?.language_name +" ":""
                    })

                }

            
                return (<li key={labelid} className="courseList">
                    
                    <div id={labelid} className='course-courseList-coursename'><span>
                         {Object.keys(checkObj).length ? <>
                            {   (JSON.parse(checkObj.player_config_json) != null || (multiConfig?.[config?._id]?.length>0))?
                                    <>
                                        <CustomTooltip title={clanguage} placement="top">
                                            <div id="circle">
                                                <div id="inner-letter">L</div>
                                            </div>
                                        </CustomTooltip>
                                    </> : <></>
                                
                            }
                        </>:<></>
                        } 
                   
                        </span>{config?.configname}</div>
                    <div className='course-courseList-btn'>
                        <div className='d-flex justify-content-end' style={{width:"120px"}}>
                        { config?.info?.config?.lan=="en_US" && Object.keys(language).length && (checkObj?.status==1 || courseApproval.includes(config._id)) ?<> 
                        {/* <button className='btn btn-author' onClick={()=>handleOpen(config)}>Localization course</button>  */}
                        <CustomTooltip title="Create multilingual package" placement="top">
                            <IconButton onClick={()=>handleOpen(config)}>
                                <GTranslateIcon  sx={{ color: '#336fe3' }}/>
                            </IconButton>
                        </CustomTooltip> 
                        {config?._id==modelId?LocalizationModal(config,checkObj):<></>}</>:<></>} 
                       <CustomTooltip title="Quiz Download" placement="top">
                            <IconButton onClick={()=> dispatch(userActions.quizDownloadReq([id,number,config?.configname]))}>
                                <CloudDownloadIcon  sx={{ color: '#336fe3' }} />
                            </IconButton>
                        </CustomTooltip>
                       <CustomTooltip title="Text Download" placement="top">
                            <IconButton onClick={()=> dispatch(userActions.textDownloadReq([id,number,config?.configname]))}>
                                <SimCardDownloadSharpIcon  sx={{ color: '#336fe3' }} />
                            </IconButton>
                        </CustomTooltip>
                     { showPackageIcon() &&  <CustomTooltip title="Package course" placement="top">
                            <IconButton onClick={()=> createScormPackage(config?._id)}>
                                <LibraryBooksIcon  sx={{ color: '#336fe3' }}/>
                            </IconButton>
                        </CustomTooltip>  }
                        </div>
                        <button className='btn btn-author' onClick={() => openPlayer(config,1,checkObj)}>Preview</button>

                         {checkAprroval(checkObj,config._id) ?<button className='btn btn-preview' onClick={() => openAuthor(config, 1)}>Ce Author </button>:<div style={{width:"116px"}}></div>} 
                         {checkAprroval(checkObj,config._id)?<button  onClick={() => handleModalOpen(config._id)} className='btn btn-preview'>Approve </button>:
                        <div style={{width:"91px",alignItems:"center",display:"flex",justifyContent:"center"}}>
                        <CustomTooltip title="Course Locked" placement="top">
                            <CheckCircleIcon  sx={{ color: '#0250b4' }}></CheckCircleIcon>
                        </CustomTooltip>
                        </div>} 
                        {/* <button className={`btn btn-author ${(isScormGenerating ) ? 'disabled' : ''}`} onClick={() => createScormPackage(config?._id)}> {(isScormGenerating && scormConfig === config?._id) ? 
                            <div className='scorm-loader'><div>Generating</div><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>:
                             'Package course'}</button> */}
                        {/* <button className={`btn btn-author `} onClick={() => createScormPackage(config?._id) }>        
                           {scromLoader && config?._id===scormConfig?
                            <div className='scorm-loader'><div>Generating</div><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>
                            :<>
                            Package course
                            </>
                           }
                             </button> */}
                    </div>
                </li>
              )})
              }
            </ul>
            </AccordionDetails>
        </Accordion>

    }

    return <div className='courses-list-holder'>
        {courseLoader && <h1 className='text-center'>Loading...</h1>}
       
        {!courseLoader &&
            <div className='courselist-continer'>
                {configs?.length > 0 && renderConfigItems(configs,isManager)}
                { categoryList?.length? categoryList.map((category, index) => {

                    return category.courses.length > 0 &&
                        <Accordion key={category.id} expanded={expanded === category.id} onChange={handleChange(category.id)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className='color-ce-green' />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>{category.category} </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='panell-continer'>
                            <ul className='course-courseList-continer' key={index}>
                                        {category?.courses?.map((course, cIndex) => {
                                            const labelid = `course-courseList-coursename-${index}`;
                                            let number=0;
                                            let id=course?.courseList?._id
                                            return (course?.courseList?.status !== -1 && <li key={cIndex} className="courseList" onMouseOver={handleIcon}>
                                                <div id={labelid} className='course-courseList-coursename'>
                                                 {course?.courseList?.info?.config &&  <span className='migrated-tag' ><img src="https://assets.contentenablers.com/website/imgs/migrated_icon.png" alt="" /></span>} {course?.courseList?.coursename}<span title={course?.courseList?._id}>{isIconShown && <img onClick={() => copyText(course?.courseList?._id)} src={CopyIcon} alt="" className='copy-tag' />}</span>
                                                </div>
                                                <div className='course-courseList-btn'>
                                                <CustomTooltip title="Quiz Download" placement="top">
                                                    <IconButton  onClick={()=> dispatch(userActions.quizDownloadReq([id,number,course?.courseList?.coursename]))}>
                                                        <CloudDownloadIcon  sx={{ color: '#336fe3' }} />
                                                    </IconButton>
                                                </CustomTooltip>
                                                <CustomTooltip title="Text Download" placement="top">
                                                    <IconButton  onClick={()=> dispatch(userActions.textDownloadReq([id,number,course?.courseList?.coursename]))}>
                                                        <SimCardDownloadSharpIcon  sx={{ color: '#336fe3' }} />
                                                    </IconButton>
                                                </CustomTooltip>
                                                 <button className='btn btn-author' onClick={() => openPlayer(course?.courseList, 0)}>Preview</button>
                                                     {course?.courseList?.status === 1 && user?.role !== 5 ? <button className='btn btn-preview' onClick={() => openAuthor(course?.courseList, 0)}>Ce Author </button> :
                                                         <div className='spacer'></div>}
                                                     {/* <button className='btn btn-author' onClick={ () => openAuthor( item ) }>Ce Author </button> */}
                                                </div>
                                                <div className='course-courseList-action'>
                                                <MoreVertIcon onClick={()=>handleMenuOpen(course.id)}  className={course.id === opened ? 'active' : 'non-active'} />
                                                 {expanded && course?.id === opened && <div className='options-area'>
                                                          <ul>
                                                                { PrivilegeHelper(user?.role).getAccess() &&  <> 
                                                                    <li key={'static_1'} onClick={() => closeMenuItem(course?.courseList, 'Assign User', 1)}>
                                                                        <span><i className='fa fa-user'></i></span>&nbsp;<span>Assign</span>
                                                                    </li>
                                                                    <li key={'static_2'} onClick={() => closeMenuItem(course?.courseList, 'Edit Details', 2)}>
                                                                        <span><i className='fa fa-pencil'></i></span>&nbsp;<span>Edit</span>
                                                                    </li> </>
                                                                }
                                                                <li key={'static_3'} onClick={() => closeMenuItem(course?.courseList, 'Updates', 4)}>
                                                                    <span><i className='fa fa-folder'></i></span>&nbsp;<span>Updates</span>
                                                                </li>
                                                                <li key={'static_4'} onClick={() => closeMenuItem(course?.courseList, 'History', 5)}>
                                                                    <span><i className='fa fa-folder'></i></span>&nbsp;<span>History</span>
                                                                </li>
                                                            </ul>
                                                    </div>}
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                }):<h1 className='text-center'>Loading...</h1>
                }</div>
        }

<ModalForm open={!!selectedConfigId} onClose={handleModalClose} configId={selectedConfigId} allCourses={multilingualConfig}/>
    </div>
}

export default CoursesList

