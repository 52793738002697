export const COURSE_INFO_REQ = "COURSE_INFO_REQ";
export const COURSE_INFO_RES = "COURSE_INFO_RES";
export const TOGGLE_SIDE_NAV = "TOGGLE_SIDE_NAV";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SELECTED_MENU = "SELECTED_MENU";

export const ADD_RLMUSER_REQ = "ADD_RLMUSER_REQ";
export const ADD_RLMUSER_RES = "ADD_RLMUSER_RES";

export const RLMUSER_LOGIN_REQ = "RLMUSER_LOGIN_REQ";
export const RLMUSER_LOGIN_RES = "RLMUSER_LOGIN_RES";

export const ALL_COURSES_REQ = "ALL_COURSES_REQ";
export const ALL_COURSES_RES = "ALL_COURSES_RES";

export const ALL_USERS_REQ = "ALL_USERS_REQ";
export const ALL_USERS_RES = "ALL_USERS_RES";

export const USER_ASSIGNED_COURSES_REQ = "USER_ASSIGNED_COURSES_REQ";
export const USER_ASSIGNED_COURSES_RES = "USER_ASSIGNED_COURSES_REQ";

export const USER_COURSE_ASSIGNMENT_REQ = "USER_COURSE_ASSIGNMENT_REQ";
export const USER_COURSE_ASSIGNMENT_RES = "USER_COURSE_ASSIGNMENT_RES";

export const COURSE_ASSIGN_REQ = "COURSE_ASSIGN_REQ";
export const COURSE_ASSIGN_RES = "COURSE_ASSIGN_RES";

export const COURSE_ASSIGNMENT_REQ = "COURSE_ASSIGNMENT_REQ";
export const COURSE_ASSIGNMENT_RES = "COURSE_ASSIGNMENT_RES";

export const TOGGLE_SIDE_SHEET = "TOGGLE_SIDE_SHEET";

export const ADMIN_ACL_REQ = "ADMIN_ACL_REQ";
export const ADMIN_ACL_RES = "ADMIN_ACL_RES";

export const UPDATE_ACL_REQ = "UPDATE_ACL_REQ";
export const UPDATE_ACL_RES = "UPDATE_ACL_RES";
export const CREATE_SCORM_PACK = "CREATE_SCORM_PACK";
export const CREATE_SCORM_PACK_SUCCESS = "CREATE_SCORM_PACK_SUCCESS";

export const ASSOCIATE_CLIENTS = "ASSOCIATE_CLIENTS";

export const LMS_AUTH_REQUEST = "LMS_AUTH_REQUEST";
export const LMS_AUTH_RESPONSE = "LMS_AUTH_RESPONSE";

export const ALL_CLIENT_REQ = "ALL_CLIENT_REQ";
export const ALL_CLIENT_RES = "ALL_CLIENT_RES";

export const LOGGEDIN_CLIENT_COURSES_REQ = "LOGGEDIN_CLIENT_COURSES_REQ";
export const LOGGEDIN_CLIENT_COURSES_RES = "LOGGEDIN_CLIENT_COURSES_RES";
export const LOGGEDOUT_CLIENT_COURSES_REQ = "LOGGEDOUT_CLIENT_COURSES_REQ";
export const LOGGEDOUT_USER_REQ = "LOGGEDOUT_USER_REQ";

export const ADD_SUPPLIMENTARY_REQ = "ADD_SUPPLIMENTARY_REQ";
export const ADD_SUPPLIMENTARY_RES = "ADD_SUPPLIMENTARY_RES";

export const UPDATE_SUPPLIMENTARY_REQ = "UPDATE_SUPPLIMENTARY_REQ";
export const UPDATE_SUPPLIMENTARY_RES = "UPDATE_SUPPLIMENTARY_RES";

export const SELECTED_SUPPLIMENTARY_REQ = "SELECTED_SUPPLIMENTARY_REQ";
export const SELECTED_SUPPLIMENTARY_RES = "SELECTED_SUPPLIMENTARY_RES";

export const SELECTED_HISTORY_REQ = "SELECTED_HISTORY_REQ";
export const SELECTED_HISTORY_RES = "SELECTED_HISTORY_RES";
export const SELECTED_HISTORY_IN_LIST = "SELECTED_HISTORY_IN_LIST";

export const ADMIN_TAGS_REQ = "ADMIN_TAGS_REQ";
export const ADMIN_TAGS_RES = "ADMIN_TAGS_RES";
export const ADMIN_TAGS_UPDATE = "ADMIN_TAGS_UPDATE";

export const STATS_DATA_REQ = "STATS_DATA_REQ";
export const STATS_DATA_RES = "STATS_DATA_RES";

export const COMPANY_DATA_REQ = "COMPANY_DATA_REQ";
export const COMPANY_DATA_RES = "COMPANY_DATA_RES";

export const SELECTED_COMPANY_DATA_REQ = "SELECTED_COMPANY_DATA_REQ";
export const SELECTED_COMPANY_DATA_RES = "SELECTED_COMPANY_DATA_RES";

export const SELECTED_COMPANY_DATA_SEAT_RES = "SELECTED_COMPANY_DATA_SEAT_RES";
export const SET_COMPANY_LOADER_DATA = "SET_COMPANY_LOADER_DATA";

export const SELECTED_CLIENT_DATA_REQ = "SELECTED_CLIENT_DATA_REQ";
export const SELECTED_CLIENT_DATA_RES = "SELECTED_CLIENT_DATA_RES";

export const SELECTED_CLIENT_COURSES_DATA_REQ = "SELECTED_CLIENT_COURSES_DATA_REQ";
export const SELECTED_CLIENT_COURSES_DATA_RES = "SELECTED_CLIENT_COURSES_DATA_RES";
export const SET_CLIENTUSER_LOADER_DATA = "SET_CLIENTUSER_LOADER_DATA";

export const COURSE_CATEGORY_REQ="COURSE_CATEGORY_REQ";
export const COURSE_CATEGORY_RES="COURSE_CATEGORY_RES";
export const USER_CACHE_REQ="USER_CACHE_REQ";
export const USER_CACHE_RES="USER_CACHE_RES";
export const ACTIVE_USER_CACHE_REQ="ACTIVE_USER_CACHE_REQ";
export const ACTIVE_USER_CACHE_RES="ACTIVE_USER_CACHE_RES";

export const COURSE_CONFIG_REQ="COURSE_CONFIG_REQ";
export const COURSE_CONFIG_RES="COURSE_CONFIG_RES";

export const SCROM_PACKAGE_REQ="SCROM_PACKAGE_REQ";
export const SCROM_PACKAGE_RES="SCROM_PACKAGE_RES";

export const LANGUAGE_PACKAGE_REQ="LANGUAGE_PACKAGE_REQ";
export const LANGUAGE_PACKAGE_RES="LANGUAGE_PACKAGE_RES";

export const LANGUAGE_CONFIG_REQ="LANGUAGE_CONFIG_REQ";
export const LANGUAGE_CONFIG_RES="LANGUAGE_CONFIG_RES";

export const SET_SCROM_LOADER = "SET_SCROM_LOADER";
export const SET_STATISTIC_TAB_DATA = "SET_STATISTIC_TAB_DATA";
export const RESET_PROGRAM_WRITER = "RESET_PROGRAM_WRITER";
export const SET_MULTILINGUAL_CONFIG = "SET_MULTILINGUAL_CONFIG";
export const SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT";

export const SET_SELECTED_CLIENT_DATA_REQ = "SET_SELECTED_CLIENT_DATA_REQ";
export const SET_SELECTED_CLIENT_DATA_RES = "SET_SELECTED_CLIENT_DATA_RES";

export const SET_COURSE_APPROVAL_REQ = "SET_COURSE_APPROVAL_REQ";
export const SET_COURSE_APPROVAL_RES = "SET_COURSE_APPROVAL_RES";

export const QUIZ_DOWNLOAD_REQ="QUIZ_DOWNLOAD_REQ";
export const QUIZ_DOWNLOAD_RES="QUIZ_DOWNLOAD_RES";
export const TEXT_DOWNLOAD_REQ="TEXT_DOWNLOAD_REQ";
export const TEXT_DOWNLOAD_RES="TEXT_DOWNLOAD_RES";
// export const DIRECT_LOGIN_RES="DIRECT_LOGIN_RES";
export const DIRECT_LOGIN_REQ="DIRECT_LOGIN_REQ";
export const DIRECT_LOGIN_FAILURE="DIRECT_LOGIN_FAILURE";
// S3 config to get clientadmin and translator
export const S3_CONFIG_REQ="S3_CONFIG_REQ";
export const S3_CONFIG_RES="S3_CONFIG_RES";
